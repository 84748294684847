@if (displayDiaolg()) {
  <p-dialog [(visible)]="displayDiaolg" [modal]="true"
            class="custom-dialog"
            [breakpoints]="{ '1199px': '80vw', '675px': '90vw' }">

    <div class="p-dialog-content">
      <!-- step 0 -->
      @if (currentStepIndex === 0) {
        <div class="grid">
          <div class="col-6 pl-6">
            <h2 class="font-bold text-3xl pt-8 m-0">{{ steps[currentStepIndex].heading }}</h2>
            <p class="m-0 text-600 text-sm font-medium">{{ steps[currentStepIndex].date }}</p>
            <h3 class="font-bold text-lg m-0 mt-5 text-xl">{{ steps[currentStepIndex].subtitle }}</h3>
            <ul class="list-none pl-0 m-0 mt-3">
              @for (item of steps[currentStepIndex].body; track $index; let last = $last) {
                <li class="flex align-items-center mb-4" [ngClass]="{ 'mb-4': !last }">
                  <svg-icon class="mr-2" name="path"></svg-icon>
                  {{ item }}
                </li>
              }
            </ul>
            <div class="flex justify-content-start mt-5 w-100 text-white">
              @if (currentStepIndex < steps.length - 1) {
                <p-button
                  label="Next"
                  class=" border-round-md text-base w-8rem box-shadow-none p-button-text-white"
                  severity="primary"
                  (click)="goToNextStep()">
                </p-button>
              }
            </div>
          </div>
          <div class="col-6 flex p-0 image-container">
            @if (steps[currentStepIndex].media.src) {
              <img [src]="assetUrl('onboarding/step0.png')" alt="Step Image" class="w-full" />
            }
          </div>
        </div>
      }
      <!--step 1-->
      @if (currentStepIndex === 1) {
        <div class="p-d-flex p-ai-center p-jc-center step-3 pt-6 pb-6 pl-5 pr-5">
          <h2 class="font-bold text-xl mb-2 p-d-flex justify-content-center text-center text-3xl mb-6">{{ steps[currentStepIndex].heading }}</h2>
          @if (steps[currentStepIndex].media.src && Array.isArray(steps[currentStepIndex].media.src)) {
            <div class="grid">
              @for (src of steps[currentStepIndex].media.src; track $index) {
                <div class="col-4">
                  <div class="image-container text-center">
                    <img [src]="assetUrl(isImageObject(src) ? src.image : src)" alt="Image"
                         class="marketplace-image w-full h-full" />
                    <p class="mt-2">{{ isImageObject(src) ? src.text : '' }}</p>
                  </div>
                </div>
              }
            </div>
          }
          <div class="flex justify-content-end mt-5 w-100 text-white">
            @if (currentStepIndex < steps.length - 1) {
              <p-button
                label="Next"
                class=" border-round-md text-base w-8rem box-shadow-none p-button-text-white"
                severity="primary"
                (click)="goToNextStep()">
              </p-button>
            }
          </div>
        </div>
      }
      <!--step 2 and after-->
      @if (currentStepIndex > 1) {
        <div class="grid p-jc-between p-ai-center p-d-flex flex-nowrap">
          <div class="image-section">
            <img [src]="assetUrl(steps[currentStepIndex].media.src)" alt="Step Image" class="w-full h-full" />
          </div>
          <div class="col-6 p-md-6 pl-5 pt-6 pb-7 pr-4">
            <h2>{{ steps[currentStepIndex].heading }}</h2>
            @for (text of steps[currentStepIndex].body; track $index) {
              <p class="m-0 text-base mb-8">{{ text }}</p>
            }
            <div class="grid justify-content-end p-mt-4 button-container gap-2">
              @if (currentStepIndex > 1) {
                <p-button
                  label="Back"
                  class="border-round-md text-base w-7rem box-shadow-none p-button-text-white"
                  [outlined]="true"
                  (click)="goToPreviousStep()">
                </p-button>
              }
              @if (currentStepIndex < steps.length - 1) {
                <p-button
                  label="Next"
                  severity="primary"
                  class=" border-round-md text-base w-7rem box-shadow-none p-button-text-white"
                  (click)="goToNextStep()">
                </p-button>
              }
              @if (currentStepIndex === steps.length - 1) {
                <p-button
                  label="Got it"
                  class="info-btn border-round-md text-base w-7rem box-shadow-none p-button-text-white text-white"
                  (click)="closeDialog()">
                </p-button>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </p-dialog>
}
