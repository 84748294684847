import { Component, OnInit, inject, signal, model } from '@angular/core';
import { OnboardingService } from '../services/onboarding.service';
import { OnboardingStep } from '../models/onboarding-step.model';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { assetUrl } from '@src/single-spa/asset-url';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-onboarding-tour',
  templateUrl: './onboarding-tour.component.html',
  standalone: true,
  imports: [DialogModule, ButtonModule, CommonModule, SvgIconComponent],
  styleUrls: ['./onboarding-tour.component.scss'],
})
export class OnboardingTourComponent implements OnInit {
  private onboardingService = inject(OnboardingService); // Injected service
  steps: OnboardingStep[] = [];
  currentStepIndex = 0;
  displayDiaolg = model(true);
  Array = Array;
  currentStep: any;

  ngOnInit() {
    this.steps = this.onboardingService.getOnboardingSteps(); // Use the injected service
  }

  goToNextStep() {
    if (this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex++;
    } else {
      this.displayDiaolg.set(false);
    }
  }

  goToPreviousStep() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
    }
  }

  closeDialog() {
    this.displayDiaolg.set(false);
  }

  isImageObject(src: any): src is { image: string; text: string } {
    return typeof src === 'object' && 'image' in src && 'text' in src;
  }

  protected readonly assetUrl = assetUrl;
}
