import { CommonModule } from '@angular/common';
import { Component, output } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { IconsModule } from '@app/shared/modules';
import { customers } from '@app/mocks';
import { ICustomer } from '@app/model';

@Component({
  selector: 'app-customer-tabs',
  standalone: true,
  imports: [CommonModule, CarouselModule, IconsModule],
  templateUrl: './customer-tabs.component.html',
  styleUrls: ['./customer-tabs.component.scss'],
})
export class CustomerTabsComponent {
  customers = customers;
  onCustomerChange = output<ICustomer>();

  selectedCustomer = customers[0];
  Infinity = Infinity;
  carouselOptions = [
    {
      breakpoint: '1024px',
      numVisible: Infinity,
      numScroll: 1,
    },
  ];

  selectCustomer(customer: ICustomer) {
    this.selectedCustomer = customer;
    this.onCustomerChange.emit(customer);
  }

  scroll(direction: string) {
    const carousel = document.querySelector('.carousel') as HTMLElement;
    const scrollAmount = direction === 'left' ? -150 : 150;
    carousel.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }
}
