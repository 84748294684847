import { enableProdMode, NgZone } from '@angular/core';

import { Router, NavigationStart, provideRouter } from '@angular/router';

import { singleSpaAngular, getSingleSpaExtraProviders } from 'single-spa-angular';

import { singleSpaPropsSubject } from './single-spa/single-spa-props';
import { provideHttpClient } from '@angular/common/http';
import { routes } from '@app/app.routes';
import { APP_BASE_HREF } from '@angular/common';
import { AppComponent } from '@app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';

// if (environment.production) {
  enableProdMode();
// }

const lifecycles = singleSpaAngular({
  bootstrapFunction: (singleSpaProps) => {
    singleSpaPropsSubject.next(singleSpaProps);
    return bootstrapApplication(AppComponent, {
      providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        getSingleSpaExtraProviders(),
        provideRouter(routes),
        provideHttpClient(),
      ],
    });
  },
  template: '<app-dashboard />',
  Router,
  NavigationStart,
  NgZone,
  domElementGetter: () => document.getElementById('dashboard-root') as HTMLElement
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
