import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { IconsModule, svgIconModule } from '@app/shared/modules';

import { SidebarService } from '../../sidebar.service';

import { IMenuItems } from '../../models';

import { APPS_ROUTES } from '@app/shared/constants';
import { assetUrl } from '@src/single-spa/asset-url';

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [IconsModule, svgIconModule],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss',
})
export class SidebarMenuComponent implements OnInit {
  constructor(private router: Router) {}
  sidebarService = inject(SidebarService);

  isCollapsed = this.sidebarService.isSidebarCollapsed;
  menuItems: IMenuItems[] = APPS_ROUTES.filter(({ isVisible }) => isVisible).map(({ component, ...rest }) => ({
    isSelected: this.router?.url.startsWith(`/${rest.path}`),
    ...rest,
  }));

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentPath = this.router.url;
        this.menuItems.forEach((route) => {
          route.isSelected = `/${route.path}` === currentPath;
        });
      }
    });
  }

  redirectTo(path: string) {
    this.router.navigate([path]);
  }

  protected readonly assetUrl = assetUrl;
}
