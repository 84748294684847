import { Routes } from '@angular/router';
import { BASE_ROUTE_URL } from '@app/shared/constants';

const ROUTES = {
  MARKETPLACE: `${BASE_ROUTE_URL}marketplace`,
  MARKETPLACE_DETAILS: `${BASE_ROUTE_URL}marketplace/details`,
  HOME: `${BASE_ROUTE_URL}home`,
  MY_PROFILE: `${BASE_ROUTE_URL}myProfile`,
  MY_CONTACTS: `${BASE_ROUTE_URL}my-contacts`,
  MY_CUSTOMERS: `${BASE_ROUTE_URL}my-customers`,
  MY_SUBSCRIPTIONS: `${BASE_ROUTE_URL}my-subscriptions`
};

export const routes: Routes = [
  { path: '', redirectTo: ROUTES.HOME, pathMatch: 'full' },
  {
    path: `${ROUTES.MARKETPLACE_DETAILS}/:id`,
    loadComponent: () =>
      import('./components/marketplace/marketplace-details/marketplace-details.component').then(
        (c) => c.MarketplaceDetailsComponent
      ),
    pathMatch: 'full'
  },
  {
    path: ROUTES.MARKETPLACE,
    loadComponent: () => import('./pages/marketplace/marketplace.component').then((c) => c.MarketplaceComponent),
    pathMatch: 'full'
  },
  {
    path: ROUTES.HOME,
    loadComponent: () => import('./pages/dashboard/dashboard.component').then((c) => c.DashboardComponent),
    pathMatch: 'full'
  },
  {
    path: ROUTES.MY_PROFILE,
    loadComponent: () => import('./pages/my-profile/my-profile.component').then((c) => c.MyProfileComponent),
    pathMatch: 'full'
  },
  {
    path: ROUTES.MY_CONTACTS,
    loadComponent: () => import('./pages/my-contacts/my-contacts.component').then((c) => c.MyContactsComponent),
    pathMatch: 'full'
  },
  {
    path: ROUTES.MY_CUSTOMERS,
    loadComponent: () => import('./pages/my-customers/my-customers.component').then((c) => c.MyCustomersComponent),
    pathMatch: 'full'
  },
  {
    path: ROUTES.MY_SUBSCRIPTIONS,
    loadComponent: () =>
      import('./pages/my-subscriptions/my-subscriptions.component').then((c) => c.MySubscriptionsComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/my-subscriptions/subscriptions-list/subscriptions-list.component').then(
            (c) => c.SubscriptionsListComponent
          )
      },
      {
        path: 'order-history',
        loadComponent: () =>
          import('./components/my-subscriptions/order-history-view/order-history-view.component').then(
            (c) => c.OrderHistoryViewComponent
          )
      },
      {
        path: 'customer-subscriptions',
        loadComponent: () =>
          import(
            './components/my-subscriptions/customer-subscriptions-view/customer-subscriptions-view.component'
          ).then((c) => c.CustomerSubscriptionsViewComponent)
      }
    ]
  },
  { path: '**', redirectTo: ROUTES.HOME }
];
