<h2 class="customer-table text-xl font-medium pl-6">{{ customerName }}</h2>
<table class="customer-table table">
  <!-- Table Header -->
  <thead class="p-datatable-thead">
    <tr>
      <th class="pl-6">Order ID</th>
      <th>Date</th>
      <th>Amount</th>
      <th>Status</th>
      <th>You earn</th>
    </tr>
  </thead>
  <tbody class="">
    <tr *ngFor="let customer of customerOrders">
      <td class="pl-6">{{ customer.orderId }}</td>
      <td>{{ customer.date }}</td>
      <td>{{ customer.amount | currency }}</td>
      <td>
        <span
          [ngClass]="{
            'status-badge font-semibold border-round-xl text-xs': true,
            'status-confirmed': customer.status === 'Confirmed',
            'status-pending': customer.status === 'Pending',
          }"
        >
          {{ customer.status }}
        </span>
      </td>
      <td>{{ customer.earnings || '—' }}</td>
    </tr>
  </tbody>
</table>
