<div class="o-suite-dashboard-container flex">
  <div class="o-suite-dashboard-container-sidebar">
    <app-sidebar />
  </div>
  <div class="o-suite-dashboard-container-body">
    <router-outlet />
  </div>
</div>
<app-onboarding-tour></app-onboarding-tour>

