import { ISidebarSliderItem } from '@app/model';

export const SIDEBAR_SLIDER_ITEMS: ISidebarSliderItem[] = [
  {
    title: 'Join our community to grow and expand your network!',
    content: 'Expand your OES community, innovate solutions',
    buttonText: 'Become an affiliate',
    iconName: 'join',
    buttonCallback: () => {},
  },
  {
    title: 'Join our community to grow and expand your network!2222',
    content: 'Expand your OES community, innovate solutions222',
    buttonText: 'Become an affiliate222',
    iconName: 'join',
    buttonCallback: () => {},
  },
  {
    title: '3rd item',
    content: '3rd item content',
    buttonText: '3rd item btn',
    iconName: 'join',
    buttonCallback: () => {},
  },
];
