import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { assetUrl } from '@src/single-spa/asset-url';

@NgModule({
  imports: [CommonModule, AngularSvgIconModule.forRoot()],
  exports: [CommonModule, AngularSvgIconModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class svgIconModule {
  constructor(private iconReg: SvgIconRegistryService) {
    // if the icon is added here we can use it just by use its name like below
    //      <svg-icon name="calendar"></svg-icon>;
    // if not
    //    <svg-icon src="calendar.svg"></svg-icon>;

    this.iconReg.loadSvg(assetUrl('icons/arrow_forward.svg'), 'arrow-right');
    this.iconReg.loadSvg(assetUrl('icons/path.svg'), 'path');
    this.iconReg.loadSvg(assetUrl('icons/fav.svg'), 'fav');
    this.iconReg.loadSvg(assetUrl('icons/help.svg'), 'help');
    this.iconReg.loadSvg(assetUrl('icons/join.svg'), 'join');
    this.iconReg.loadSvg(assetUrl('icons/orders.svg'), 'orders');
    this.iconReg.loadSvg(assetUrl('icons/collapse.svg'), 'collapse');
    this.iconReg.loadSvg(assetUrl('icons/calendar.svg'), 'calendar');
    this.iconReg.loadSvg(assetUrl('icons/contacts.svg'), 'contacts');
    this.iconReg.loadSvg(assetUrl('icons/customers.svg'), 'customers');
    this.iconReg.loadSvg(assetUrl('icons/dashboard.svg'), 'dashboard');
    this.iconReg.loadSvg(assetUrl('icons/marketplace.svg'), 'marketplace');
    this.iconReg.loadSvg(assetUrl('icons/subscriptions.svg'), 'subscriptions');
    // // Register other SVG icons here
  }
}
