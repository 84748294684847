import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { assetUrl } from '@src/single-spa/asset-url';

@Component({
  selector: 'app-upcoming-products',
  standalone: true,
  imports: [CommonModule, CarouselModule, ButtonModule],
  templateUrl: './upcoming-products.component.html',
  styleUrls: ['./upcoming-products.component.scss'],
})
export class UpcomingProductsComponent {
  products = [
    {
      title: 'OBooking',
      description:
        'Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole.',
      image: 'assets/upcoming.png',
      caption: 'Your Seamless OBooking System on the Go!',
    },
    {
      title: 'OTravel',
      description: 'Next-level travel experiences. Sustainable, adventurous, and completely hassle-free.',
      image: 'assets/upcoming.png',
      caption: 'Your ultimate travel companion!',
    },
    {
      title: 'OManage',
      description: 'Effortlessly manage your projects and tasks with streamlined organization and focus.',
      image: 'assets/upcoming.png',
      caption: 'Managing projects made easy!',
    },
    {
      title: 'OBooking',
      description:
        'Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole.',
      image: 'assets/upcoming.png',
      caption: 'Your Seamless OBooking System on the Go!',
    },
    {
      title: 'OTravel',
      description: 'Next-level travel experiences. Sustainable, adventurous, and completely hassle-free.',
      image: 'assets/upcoming.png',
      caption: 'Your ultimate travel companion!',
    },
    {
      title: 'OManage',
      description: 'Effortlessly manage your projects and tasks with streamlined organization and focus.',
      image: 'assets/upcoming.png',
      caption: 'Managing projects made easy!',
    },
    {
      title: 'OBooking',
      description:
        'Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole.',
      image: 'assets/upcoming.png',
      caption: 'Your Seamless OBooking System on the Go!',
    },
    {
      title: 'OTravel',
      description: 'Next-level travel experiences. Sustainable, adventurous, and completely hassle-free.',
      image: 'assets/upcoming.png',
      caption: 'Your ultimate travel companion!',
    },
    {
      title: 'OManage',
      description: 'Effortlessly manage your projects and tasks with streamlined organization and focus.',
      image: 'assets/upcoming.png',
      caption: 'Managing projects made easy!',
    },
    {
      title: 'OBooking',
      description:
        'Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole.',
      image: 'assets/upcoming.png',
      caption: 'Your Seamless OBooking System on the Go!',
    },
    {
      title: 'OTravel',
      description: 'Next-level travel experiences. Sustainable, adventurous, and completely hassle-free.',
      image: 'assets/upcoming.png',
      caption: 'Your ultimate travel companion!',
    },
    {
      title: 'OManage',
      description: 'Effortlessly manage your projects and tasks with streamlined organization and focus.',
      image: 'assets/upcoming.png',
      caption: 'Managing projects made easy!',
    },
  ];
  activeIndex = 0;

  onIndicatorClick(index: number): void {
    this.activeIndex = index;
  }

  protected readonly assetUrl = assetUrl;
}
