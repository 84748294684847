<div class="top-customers pb-4 border-bottom-1 relative overflow-hidden">
  <div class="title flex gap-2 align-items-center p-3">
    <fa-icon icon="users" class="logo flex justify-content-center align-items-center shadow-1 text-sm border border-circle"></fa-icon>
    <h2 class="text-xl font-medium m-0">Top customers</h2>
  </div>
  <div class="custom-carousel overflow-auto flex align-items-start gap-3 px-6">
    <div *ngFor="let customer of customers">
      <div class="flex flex-column align-items-center cursor-pointer" (click)="selectCustomer(customer)">
        <ng-container *ngIf="customer.name === 'All'; else notAll">
          <fa-icon
            icon="users"
            class="avatar all flex justify-content-center align-items-center shadow-1"
            [ngClass]="{ 'selected-customer all-users border-3 border-round-xl': customer.id === selectedCustomer.id }"
          ></fa-icon>
        </ng-container>
        <ng-template #notAll>
          <img
            [src]="customer.image"
            [alt]="customer.name"
            class="shadow-1 avatar border-round-lg inline-block"
            [ngClass]="{ 'selected-customer border-3 border-round-xl': customer.id === selectedCustomer.id }"
          />
        </ng-template>
        <span class="mt-2 text-sm font-medium">{{ customer.name }}</span>
        <div *ngIf="customer.id === selectedCustomer.id" class="selected-indicator absolute mt-2"></div>
      </div>
    </div>
  </div>
</div>
