export enum FilterType {
  ALL = 'all',
  PURCHASED = 'purchased',
  FAVORITES = 'favorites'
}

export enum MyContactsFilterType {
  CONTACTS = 'contacts',
  GROUPS = 'groups',
  FAVORITES = 'favorites'
}
export enum MyCustomersFilterType {
  ALL = 'All',
  FAVORITES = 'favorites'
}
