import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconsModule } from '@app/shared/modules';

@Component({
  selector: 'app-total-customers',
  standalone: true,
  imports: [IconsModule],
  templateUrl: './total-customers.component.html',
  styleUrl: './total-customers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalCustomersComponent {
  @Input() count: number = 0;
  @Input() title: string = 'Total customers';
  @Input() iconPath: string = 'assets/icons/customers-icon.png';
}
