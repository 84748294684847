import { DashboardComponent } from '@app/pages/dashboard/dashboard.component';
import { MarketplaceComponent } from '@app/pages/marketplace/marketplace.component';
import { MyCustomersComponent } from '@app/pages/my-customers/my-customers.component';
import { BASE_ROUTE_URL } from '@app/shared/constants/global.constant';

export const APPS_ROUTES = [
  {
    label: 'Dashboard',
    icon: 'dashboard',
    isVisible: true,
    path: `${BASE_ROUTE_URL}home`,
    soon: false
  },
  {
    label: 'Marketplace',
    icon: 'marketplace',
    isVisible: true,
    path: `${BASE_ROUTE_URL}marketplace`,
    soon: false
  },
  // {
  //   label: 'My favorites',
  //   icon: 'fav',
  //   isVisible: true,
  //   path: `${BASE_ROUTE_URL}my-favorites`,
  //   component: DashboardComponent,
  //   soon:true
  // },
  {
    icon: 'subscriptions',
    label: 'My subscriptions',
    isVisible: true,
    path: `${BASE_ROUTE_URL}my-subscriptions`,
    soon: false
  },
  {
    label: 'My orders',
    icon: 'orders',
    isVisible: false,
    path: `${BASE_ROUTE_URL}my-orders`,
    soon: true
  },
  {
    label: 'My contacts',
    icon: 'contacts',
    isVisible: true,
    path: `${BASE_ROUTE_URL}my-contacts`,
    soon: false
  },
  {
    label: 'Customers',
    icon: 'customers',
    isVisible: true,
    path: `${BASE_ROUTE_URL}my-customers`,
    soon: false
  }
  // {
  //   label: 'My calender',
  //   icon: 'calendar',
  //   isVisible: true,
  //   path: `${BASE_ROUTE_URL}my-calender`,
  //   component: DashboardComponent
  // },
];
