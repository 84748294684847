import { Injectable } from '@angular/core';
import { OnboardingStep } from '../models/onboarding-step.model';

@Injectable({
  providedIn: 'root'
})

export class OnboardingService {
  getOnboardingSteps(): OnboardingStep[] {
    return [
      {
        heading: 'What a Powerful Comeback!',
        subtitle: 'So inspiring!',
        body: [
          'To see how loyal and engaged our community is.',
          'We are moving forward better and faster than predicted!',
          'Way to go.',
        ],
        media: {
          type: 'images',
          src: 'onboarding/step0.png',
        },
        date: 'Oct 10, 2024'
      },

        {
          heading: 'Welcome to Marketplace',
          media: {
            type: 'images',
            src: [
              {
                image: 'onboarding/marketplace.png',
                text: 'A single hub to manage all your essential daily apps.',
              },
              {
                image: 'onboarding/onfusion.png',
                text: 'Introducing a centralized app switcher that brings all your applications together.',
              },
              {
                image: 'onboarding/category.png',
                text: 'New apps are designed to spark your creativity.',
              },
            ],
          },

      },
      {
        heading: 'A centralized app switcher',
        body: [
          'Introducing an advanced centralized app switcher, designed to bring all your applications together in a single, easy-to-navigate location. With just one click, you can seamlessly transition between work, communication, productivity, and creative tools - saving you time and reducing distractions.',
          'This smart switcher is tailored to keep everything essential at your fingertips, offering quick previews, personalized shortcuts, and organized categories to help you maintain focus and optimize productivity throughout your day.'
        ],
        media: { type: 'image', src: 'onboarding/step3.png' },
        date: undefined
      },
      {
        heading: 'Unlock creativity now!',
        body: [
          'Introducing an advanced centralized app switcher, designed to bring all your applications together in a single, easy-to-navigate location. With just one click, you can seamlessly transition between work, communication, productivity, and creative tools - saving you time and reducing distractions.',
          'This smart switcher is tailored to keep everything essential at your fingertips, offering quick previews, personalized shortcuts, and organized categories to help you maintain focus and optimize productivity throughout your day.'
        ],
        media: { type: 'image', src: 'onboarding/step4.png' },
        date: undefined
      },
    ];
  }
}
