<div class="top-customers shadow-1 border-round-lg bg-white">
  @if (customers&&customers.length>0) {
  <div class="flex justify-content-between flex-column h-full">
    <app-customer-tabs (onCustomerChange)="updateCustomerOrders($event)" />
    <app-customer-table [customerOrders]="customerOrders" [customerName]="customerName" />
  </div>
  } @else {
  <app-empty-state />
  }
</div>
