import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActiveTimelineComponent } from './active-timeline/active-timeline.component';
import { TimelineInfoComponent } from './timeline-info/timeline-info.component';

@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [ActiveTimelineComponent, TimelineInfoComponent],
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {}
