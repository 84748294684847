<div
  class="total-revenue-card p-3 pb-0 surface-card border-round-lg shadow-1 flex flex-column gap-2 justify-content-between border-round-lg shadow-1 relative border-round-lg relative h-full w-full shadow-1"
>
  <div class="header flex justify-content-between align-items-center">
    <!-- Title Section -->
    <div class="title flex align-items-center gap-2">
      <div class="icon-revenue border-circle flex align-items-center justify-content-center">
        <img [src]="assetUrl('icons/revenue.svg')"  alt="Total revenue" />
      </div>
      <span class="text-md font-medium">Total revenue</span>
    </div>
    <!-- Badges -->
    <div class="badges flex gap-2">
      <span class="commission-badge border-round-md px-2 py-1 font-semibold text-xs border-round-2xl">
        Commission $559.00
      </span>
      <span class="credit-badge border-round-md px-2 py-1 font-semibold">18,299 Credit</span>
    </div>
  </div>

  <div class="content flex flex-column align-items-start mt-3">
    <div class="revenue-amount font-bold text-dark mb-3 absolute text-center z-5 pointer-events-none">$18,299.07</div>
    <div class="chart-container w-full mt-2">
      <div echarts [options]="options" class="echart"></div>
    </div>
  </div>
</div>
