import { ICustomerOrder } from '@app/model';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, input } from '@angular/core';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-customer-table',
  standalone: true,
  imports: [TableModule, CommonModule],
  templateUrl: './customer-table.component.html',
  styleUrl: './customer-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerTableComponent {
  @Input() customerOrders: ICustomerOrder[] = [];
  @Input() customerName = '';
}
