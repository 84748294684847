import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ICustomerInfo,
  ICustomerOrder,
  IDashboardRevenue,
  IInviteTemplate,
  ITimeline,
  ITopCustomer,
  IUpcomingProduct
} from '@app/model';
import { IApiResponseWithData } from '@app/model/api.model';
import { IBillingInfo, ICountry, IProfileInfo } from '@app/model/myProfile.model';
import { environment } from '@src/environments/environment';
import { map, Observable } from 'rxjs';
import { Product, ProductById } from '@app/model/product.model';
import { FilterType } from '@app/shared/enums/ filter.enum';
import { ApiResponse } from '@app/components/onboarding/models/onboarding-step.model';
import { IContactsData, ICountryData, IStatesData } from '@app/shared/modules/contacts.model';

@Injectable({
  providedIn: 'root'
})
export class OsuiteApi {
  osuite_ApiUrl = environment.apiUrl;
  osuite_SSO_ApiUrl = environment.apiUrlSSO;

  private httpClient = inject(HttpClient);

  fetchUpcomingProducts(): Observable<IApiResponseWithData<any>> {
    return this.httpClient
      .get(this.osuite_ApiUrl + 'marketplace/upcoming-products')
      .pipe(map((response) => response as IApiResponseWithData<IUpcomingProduct[]>));
  }

  fetchDashboardRevenue(fromYear: number, toYear: number, fromMonth: number, toMonth: number) {
    return this.httpClient
      .get(
        this.osuite_ApiUrl +
          `myearnings/revenue-dashboard?fromMonth=${fromMonth}&fromYear=${fromYear}&toMonth=${toMonth}&toYear=${toYear}`
      )
      .pipe(map((response) => response as IApiResponseWithData<IDashboardRevenue>));
  }

  fetchDashboardTotalRevenue() {
    return this.httpClient
      .get(this.osuite_ApiUrl + `myearnings/total-revenue`)
      .pipe(map((response) => response as IApiResponseWithData<any>));
  }

  fetchTimelineData() {
    return this.httpClient
      .get(this.osuite_ApiUrl + `myearnings/timeline-commissions`)
      .pipe(map((response) => response as IApiResponseWithData<ITimeline[]>));
  }

  fetchTopCustomers() {
    const url = `${this.osuite_ApiUrl}api/v2/dashboards/topCustomers`;

    return this.httpClient.get<IApiResponseWithData<ITopCustomer[]>>(url).pipe(map((response) => response));
  }

  fetchCustomerInfo() {
    const url = `${this.osuite_ApiUrl}api/v2/dashboards/count`;
    return this.httpClient.get<IApiResponseWithData<ICustomerInfo>>(url).pipe(map((response) => response));
  }

  fetchMyTotalEarnings(
    start: number,
    limit: number,
    searchValue: string,
    creditType: string,
    productId: number,
    orderBy: string
  ) {
    return this.httpClient
      .post(this.osuite_ApiUrl + `myearnings/commissions`, {
        start: start,
        limit: limit,
        searchValue: searchValue,
        creditType: creditType,
        productId: productId,
        orderBy: orderBy
      })
      .pipe(map((response) => response as IApiResponseWithData<{ commissionDetails: ICustomerOrder[] }>));
  }

  fetchMarketplaceProducts(filter: FilterType, filterFavorite: boolean) {
    let queryParams = new URLSearchParams();
    if (filter === FilterType.PURCHASED) {
      queryParams.set('isSubscribed', 'true');
    }
    if (filterFavorite) {
      queryParams.set('isFavorite', 'true');
    }

    const url = `${this.osuite_ApiUrl}api/v2/marketplace/products?${queryParams.toString()}`;

    return this.httpClient.post(url, {}).pipe(map((response) => response as IApiResponseWithData<Product>));
  }

  setMarketplaceProducts(body: any) {
    const url = `${this.osuite_ApiUrl}myFavorites/products/save`;

    return this.httpClient.post(url, body).pipe(map((response) => response as IApiResponseWithData<any>));
  }

  deleteFavProducts(productId: number) {
    const url = `${this.osuite_ApiUrl}api/v2/myFavorites/products/${productId}`;

    return this.httpClient.delete(url).pipe(map((response) => response as IApiResponseWithData<any>));
  }

  fetchProfileInfo() {
    return this.httpClient
      .get(this.osuite_ApiUrl + `api/v2/users/profile`)
      .pipe(map((response) => response as IApiResponseWithData<IProfileInfo>));
  }

  updateProfileInfo(updatedData: any) {
    return this.httpClient
      .put<IApiResponseWithData<IProfileInfo>>(this.osuite_ApiUrl + `update-profile`, updatedData)
  }

  uploadProfilePic(file: FormData) {
    return this.httpClient.post(this.osuite_ApiUrl + `uploadProfilePic`, file);
  }

  clearProfilePicture() {
    return this.httpClient.delete(this.osuite_ApiUrl + `delete-profile-picture`);
  }

  fetchBillingAddress() {
    return this.httpClient
      .get(this.osuite_ApiUrl + `billing/billId`)
      .pipe(map((response) => response as IApiResponseWithData<IBillingInfo>));
  }

  updateBillingAddress(data: IBillingInfo) {
    return this.httpClient
      .post<IApiResponseWithData<IBillingInfo>>(this.osuite_ApiUrl + `billing/saveOrUpdate`, data)
  }

  fetchCountries() {
    return this.httpClient
      .get(this.osuite_ApiUrl + `countries`)
      .pipe(map((response) => response as IApiResponseWithData<ICountry[]>));
  }

  fetchStatesByCountryId(countryId: number) {
    return this.httpClient.get(this.osuite_ApiUrl + `getStatesByCountryId/${countryId}`);
  }

  fetchCitiesByStateId(stateId: number) {
    return this.httpClient.get(this.osuite_ApiUrl + `getCitiesByStateId/${stateId}`);
  }

  resetPassword(changePasswordData: any) {
    return this.httpClient.put<IApiResponseWithData<any>>(
      this.osuite_SSO_ApiUrl + `api/user/change-password`,
      changePasswordData
    );
  }

  fetchFirstLogin(): any {
    return this.httpClient.get<ApiResponse>(this.osuite_SSO_ApiUrl + `api/user/user-info`);
  }

  saveNDA(NDAData: any) {
    return this.httpClient.post<IApiResponseWithData<any>>(this.osuite_ApiUrl + `saveNdaSignature`, NDAData);
  }

  fetchInvitationTemplates(productName: string) {
    return this.httpClient.get<IApiResponseWithData<IInviteTemplate[]>>(
      this.osuite_ApiUrl +
        `sendinvitesbyproductName?productName=${productName}
    `
    );
  }

  fetchInvites(data: any) {
    return this.httpClient.post<IApiResponseWithData<undefined>>(this.osuite_ApiUrl + `getMyInvitee`, data);
  }

  sendInvites(inviteData: { promotionId: number; emailId: string }) {
    return this.httpClient.post<IApiResponseWithData<undefined>>(this.osuite_ApiUrl + `sendInvitee`, inviteData);
  }

  getCountries(): Observable<IApiResponseWithData<ICountryData[]>> {
    return this.httpClient.get<IApiResponseWithData<ICountryData[]>>(this.osuite_ApiUrl + 'getCountries');
  }

  getStatesByCountryId(countryId: number): Observable<IApiResponseWithData<IStatesData[]>> {
    return this.httpClient.get<IApiResponseWithData<IStatesData[]>>(
      this.osuite_ApiUrl + 'getStatesByCountryId/' + countryId
    );
  }

  editContact(formData: FormData): Observable<IApiResponseWithData<any>> {
    return this.httpClient.post<IApiResponseWithData<any>>(this.osuite_ApiUrl + 'saveOrUpdateContact', formData);
  }

  getContactInfoByAlternativeEmail(alternateEmailId: string): Observable<IApiResponseWithData<IContactsData>> {
    return this.httpClient.get<IApiResponseWithData<IContactsData>>(
      this.osuite_ApiUrl + 'alternateEmailId/' + alternateEmailId
    );
  }

  createKYCSession() {
    return this.httpClient.post<IApiResponseWithData<any>>(this.osuite_SSO_ApiUrl + `api/kyc/session`, {});
  }

  getPlanInfoById(id: number) {
    return this.httpClient.get<IApiResponseWithData<ProductById>>(
      this.osuite_ApiUrl + `api/v2/marketplace/products/${id}`
    );
  }

  productOneTimePayment(productPlan: number) {
    return this.httpClient.get<IApiResponseWithData<any>>(
      this.osuite_ApiUrl + `api/payments/subscription-checkout-url/${productPlan}`
    );
  }

  getProductFeature(productId: number) {
    return this.httpClient.get<IApiResponseWithData<any>>(
      this.osuite_ApiUrl + `products/${productId}/features/comparison`
    );
  }

  setRefreshToken() {
    const url = `${this.osuite_SSO_ApiUrl}api/user/token/refresh`;
    return this.httpClient.post(url, {});
  }
}
