import { Component, OnInit } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { assetUrl } from '@src/single-spa/asset-url';

@Component({
  selector: 'app-total-revenue',
  standalone: true,
  imports: [NgxEchartsModule],
  templateUrl: './total-revenue.component.html',
  styleUrls: ['./total-revenue.component.scss'],
})
export class TotalRevenueComponent implements OnInit {
  options: any;

  ngOnInit(): void {
    this.options = {
      tooltip: {
        show: false, // Disable tooltip since the design doesn't need it
      },
      grid: {
        left: '0%',
        right: '0%',
        bottom: '0%',
        top: '0%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        show: false, // Hide the X-axis
        boundaryGap: false,
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
      yAxis: {
        type: 'value',
        show: false, // Hide the Y-axis
      },
      series: [
        {
          data: [
            80, 20, 120, 25, 90, 35, 40, 70, 50, 20, 60, 65, 70, 20, 120, 25, 90, 35, 40, 70, 50, 20, 60, 65, 70, 75,
            80, 75, 80, 85, 90, 95, 100, 105,
          ],
          type: 'line',
          smooth: false, // Makes the curve smooth
          areaStyle: {
            color: '#E6F7FF', // Light blue gradient fill under the curve
          },
          lineStyle: {
            color: '#00b5ea',
          },
          symbol: 'none', // Hide any points
        },
      ],
    };
  }

  protected readonly assetUrl = assetUrl;
}
