<div
  class="total-customers h-full w-full flex flex-column justify-content-center align-items-center border-round-lg shadow-1"
>
  <div class="icon-container flex justify-content-center align-items-center border-round-lg">
    <fa-icon icon="users"></fa-icon>
  </div>

  <div class="title mt-3 text-sm text-center">Total customers</div>
  <div class="count mt-2 font-bold text-center">82</div>
</div>
