import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { IconsModule, svgIconModule } from '@app/shared/modules';

import { SidebarService } from '../../sidebar.service';

import { IMenuItems } from '../../models';

import { APPS_ROUTES, BASE_ROUTE_URL } from '@app/shared/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { TagModule } from 'primeng/tag';
@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [IconsModule, svgIconModule, TagModule],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss'
})
export class SidebarMenuComponent implements OnInit {
  constructor(private router: Router) {}
  sidebarService = inject(SidebarService);
  private destroyRef = inject(DestroyRef);

  isCollapsed = this.sidebarService.isSidebarCollapsed;
  menuItems: IMenuItems[] = APPS_ROUTES.filter(({ isVisible }) => isVisible).map(({ ...rest }) => ({
    isSelected: this.router?.url.startsWith(`/${rest.path}`),
    ...rest
  }));

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {})
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const currentPath = this.router.url;
          this.menuItems.forEach((route) => {
            route.isSelected = currentPath.startsWith(`/${route.path}`);
          });
        }
      });
  }

  redirectTo(item: IMenuItems) {
    if (item.soon) {
      return;
    }

    this.router.navigate([item.path]);
  }
}
