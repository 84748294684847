import { Routes } from '@angular/router';

import { APPS_ROUTES } from './shared/constants';
import { EmptyRouteComponent } from '@app/empty-route/empty-route.component';
//
// const validRoutes = APPS_ROUTES.filter(({ isVisible }) => isVisible).map(({ component, path }) => ({
//   path,
//   component,
// }));
//
// export const routes: Routes = [...validRoutes];
export const routes: Routes = [
  { path: '', component: EmptyRouteComponent, pathMatch: 'full' },
  { path: 'dashboard/home', component: EmptyRouteComponent },
  { path: '**', redirectTo: 'dashboard/home' },
];
