<div [ngClass]="{'dashboard-sidebar': true,'collapsed': isCollapsed()}">
  <div class="dashboard-sidebar-menu">
    <app-sidebar-menu />
  </div>
  @if(!isCollapsed()) {
  <div class="dashboard-sidebar-slider">
    <app-sidebar-slider />
  </div>
  }
  <div class="dashboard-sidebar-footer">
    <div class="dashboard-sidebar-footer-icons">
      <div class="clickable-icon" (click)="op.toggle($event)"><svg-icon name="help" />
        <p-overlayPanel #op styleClass="footer-popper">
          <div class="popper-container">
            <!-- TODO: change icons and make these elements clickable-->
            <label class="popper-item">
              <div class="icon"><fa-icon icon="question-circle" class="fa-regular" /></div>
              <div class="text">FAQs</div>
            </label>
            <label class="popper-item">
              <div class="icon"><fa-icon icon="question-circle" class="" /></div>
              <div class="text">User manual</div>
            </label>
            <label class="popper-item">
              <div class="icon"><fa-icon icon="question-circle" class="" /></div>
              <div class="text">Documents</div>
            </label>
          </div>
        </p-overlayPanel>
      </div>
      <div class="clickable-icon" (click)="onHandleSidebarToggle()">
        <svg-icon name="collapse" />
      </div>
    </div>
  </div>
</div>
