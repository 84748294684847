import { Injectable } from '@angular/core';
import { IProfileInfo } from '@app/model/myProfile.model';

@Injectable({
  providedIn: 'root'
})
export class MicrofrontendsSharedService {

  shareProfileInfo(profileInfo: Partial<IProfileInfo>) {
    window.dispatchEvent(new CustomEvent('dashboardApp-user-profile-updated', {
      detail: {
        firstName: profileInfo.firstName,
        lastName: profileInfo.lastName
      }
    }));
  }

  shareProfilePic(profilePic: string) {
    window.dispatchEvent(new CustomEvent('dashboardApp-user-profile-pic-updated', {
      detail: {
        profilePic: profilePic
      }
    }));
  }
}
