<div class="top-customers-card surface-card shadow-2 border-round p-4 flex flex-column align-items-center h-full">
  <div class="header text-center">
    <img [src]="'top-customers.png'" alt="top customers" class="empty-state-image" width="150px" />
  </div>
  <p class="message text-sm text-center mb-1">
    It seems there are currently no top customers to display!
    <br />
    This is a great opportunity to focus on building your customer base!
  </p>
  <h2 class="text-lg font-bold text-gray-800 text-center mb-3">Tips to Increase Engagement:</h2>
  <ul class="tips-list list-none p-0 m-0 w-full">
    <li class="border-round p-2 text-sm text-center mb-2">Reach out to previous customers with special offers</li>
    <li class="border-round p-2 text-sm text-center mb-2">
      Consider customer loyalty programs to encourage repeat business
    </li>
    <li class="border-round p-2 text-sm text-center mb-2">Enhance your marketing efforts through social media</li>
  </ul>
  <p class="footer-message text-sm mt-4 text-center">
    Let’s work towards filling this space with your success stories!
  </p>
</div>
