<div [ngClass]="{'sidebar-menu': true, 'collapsed': isCollapsed()}">
  @for (item of menuItems; track $index) {
  <div [ngClass]="{'item-container': true, 'active': item.isSelected}">
    @if(item.isSelected) {
    <div class="active-indication"></div>
    }
    <div class="item-button" (click)="redirectTo(item.path)">
      <svg-icon [name]="item.icon" class="menu-icon"></svg-icon>
      <div class="item-button-label">{{item.label}}</div>
    </div>
  </div>
  }
</div>
