import { DashboardComponent } from '@app/pages/dashboard/dashboard.component';
import { MarketplaceComponent } from '@app/pages/marketplace/marketplace.component';

export const APPS_ROUTES = [
  { label: 'Dashboard', icon: 'dashboard', isVisible: true, path: '', component: DashboardComponent },
  {
    label: 'Marketplace',
    icon: 'marketplace',
    isVisible: true,
    path: 'marketplace',
    component: MarketplaceComponent,
  },
  {
    label: 'My favorites',
    icon: 'fav',
    isVisible: true,
    path: 'my-favorites',
    component: DashboardComponent,
  },
  {
    icon: 'subscriptions',
    label: 'My subscriptions',
    isVisible: true,
    path: 'my-subscriptions',
    component: DashboardComponent,
  },
  { label: 'My orders', icon: 'orders', isVisible: false, path: 'my-orders', component: DashboardComponent },
  { label: 'My contacts', icon: 'contacts', isVisible: true, path: 'my-contacts', component: DashboardComponent },
  { label: 'Customers', icon: 'customers', isVisible: true, path: 'customers', component: DashboardComponent },
  { label: 'My calender', icon: 'calendar', isVisible: true, path: 'my-calender', component: DashboardComponent },
];
