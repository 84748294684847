<div class="timeline-info-container">
  <!-- TODO: will replace with an svg image -->
  <div class="icon mb-3">
    <img [src]="assetUrl('assets/timeline.png')" alt="Timeline Icon" />
  </div>

  <!-- Title -->
  <h2 class="title">Stay on top of your timeline!</h2>

  <!-- Description -->
  <p class="description">Effortlessly track your transaction orders with real-time updates!</p>
</div>
