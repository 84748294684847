<div class="unlock-potential-container p-3 text-center">
  <!-- TODO: will replace with an svg image -->
  <div class="icon mb-3">
    <img [src]="assetUrl('revenue.png')" alt="Growth Chart Icon" />
  </div>

  <!-- Title -->
  <h2 class="title font-bold mb-3">Unlock Your Potential!</h2>

  <!-- Description -->
  <p class="description text-sm">
    Let’s turn these numbers into a story of success. With every sale, you’re not just contributing to revenue; you’re
    building a brighter future for yourself and our team.
  </p>

  <!-- Highlighted Footer Text -->
  <p class="highlight text-sm font-bold m-0">
    Every great achievement starts with a single step. Let’s fill this chart with our success!
  </p>
</div>
