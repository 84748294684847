<div
  class="upcoming-products overflow-hidden h-full w-full border-1 overflow-hidden border-round-lg shadow-1 p-3 flex flex-column justify-content-between"
>
  <h3 class="header-title flex font-bold align-items-center flex m-0 mb-2 gap-2">
    <div class="icon border-circle flex align-items-center justify-content-center">
      <img [src]="assetUrl('icons/box.svg')"  alt="upcoming products" />
    </div>
    Upcoming Products
  </h3>
  <p-carousel
    [value]="products"
    [numVisible]="1"
    [numScroll]="1"
    [circular]="true"
    [autoplayInterval]="3000"
    [showNavigators]="false"
    class="custom-carousel flex-1"
  >
    <ng-template pTemplate="item" let-product>
      <div class="flex flex-md-row product-card gap-3 w-full pl-5">
        <!-- Product Information -->
        <div class="mb-md-0 pr-md-4 product-info">
          <h4 class="product-title font-bold m-0 mb-2">{{ product.title }}</h4>
          <p class="product-description text-xs mb-3">
            {{ product.description }}
          </p>
          <button pButton type="button" label="Learn more" class="p-button-outlined learn-more"></button>
        </div>

        <!-- Pro</a>duct Image -->
        <div class="product-image text-center">
          <div class="image-box">
            <img [src]="assetUrl('assets/upcoming.png')"  alt="OBooking System" width="50px" />
            <p class="image-caption">
              {{ product.caption }}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
