import { Component, OnInit } from '@angular/core';

import { RevenueComponent } from './revenue/revenue.component';
import { UnlockPotenialComponent } from './unlock-potenial/unlock-potenial.component';

@Component({
  selector: 'app-revenue-overview',
  standalone: true,
  imports: [RevenueComponent, UnlockPotenialComponent],
  templateUrl: './revenue-overview.component.html',
  styleUrls: ['./revenue-overview.component.scss'],
})
export class RevenueOverviewComponent {}
