import { Component } from '@angular/core';

import { ButtonModule } from 'primeng/button';

import { CarouselModule } from 'primeng/carousel';
import { svgIconModule } from '@app/shared/modules';

import { ISidebarSliderItem } from '@app/model';

import { SIDEBAR_SLIDER_ITEMS } from '@app/mocks';

@Component({
  selector: 'app-sidebar-slider',
  standalone: true,
  imports: [svgIconModule, ButtonModule, CarouselModule],
  templateUrl: './sidebar-slider.component.html',
  styleUrl: './sidebar-slider.component.scss',
})
export class SidebarSliderComponent {
  sliderItems: ISidebarSliderItem[] = SIDEBAR_SLIDER_ITEMS;
  carousalIndicationClass = this.sliderItems.length > 2 ? 'slider-indicator multiple' : 'slider-indicator';
}
