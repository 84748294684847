<div class="slider-container">
  <p-carousel [hidden]="true" indicatorsContentClass="slider-indicator-container" [indicatorStyleClass]="carousalIndicationClass"
    [numVisible]="1" [numScroll]="1" [circular]="true" [autoplayInterval]="3000" [value]="sliderItems"
    [showNavigators]="false" class="custom-carousel flex-1">
    <ng-template pTemplate="item" let-item>
      <div class="carousal-container">
        <div class="image-container"><svg-icon [name]="item.iconName" class="menu-icon"></svg-icon>
        </div>
        <div class="body-container">
          <div class="title-container">
            <div class="title-text">
              {{ item.title}}
            </div>
          </div>
          <div class="content-container">
            <div class="content-text">
              {{ item.content }}
            </div>
          </div>
          <div class="button-container">
            <p-button [label]="item.buttonText" class="w-full slider-btn" type="submit"
              (click)="item.buttonCallback()" />
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
