import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IApiResponseWithData, PaginatedResponseCustomers } from '@app/model/api.model';
import { ExportCustomersOptions, GetCustomersOptions, ICustomer } from '@app/model/my-customers.model';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyCustomersApi {
  osuite_ApiUrl = environment.apiUrl;
  osuite_SSO_ApiUrl = environment.apiUrlSSO;

  private httpClient = inject(HttpClient);

  fetchCustomers(
    parameters: GetCustomersOptions
  ): Observable<IApiResponseWithData<PaginatedResponseCustomers<ICustomer>>> {
    return this.httpClient.get<IApiResponseWithData<PaginatedResponseCustomers<ICustomer>>>(
      this.osuite_ApiUrl +
        `myAffiliates/customers?page=${parameters.page}&isFavorite=${parameters.isFavourite}&searchValue=${parameters.searchValue}&size=${parameters.size}`
    );
  }

  exportCustomers(parameters: ExportCustomersOptions): Observable<Blob> {
    return this.httpClient.post(this.osuite_ApiUrl + `myAffiliates/export-to-file`, parameters, {
      responseType: 'blob'
    });
  }

  AddCustomerFavourite(favCustomerAccountId: number) {
    return this.httpClient.post<IApiResponseWithData<any>>(this.osuite_ApiUrl + `api/v2/myFavorites/customers`, {
      favCustomerAccountId: favCustomerAccountId
    });
  }

  removeCustomerFavourite(favCustomerAccountId: number) {
    return this.httpClient.delete<IApiResponseWithData<any>>(
      this.osuite_ApiUrl + `api/v2/myFavorites/customer/${favCustomerAccountId}`
    );
  }
}
