import { Component } from '@angular/core';
import { CustomerTabsComponent } from './customer-tabs/customer-tabs.component';
import { CustomerTableComponent } from './customer-table/customer-table.component';
import { customersOrders, customers } from '@app/mocks';
import { ICustomer } from '@app/model';
import { EmptyStateComponent } from './empty-state/empty-state.component';

@Component({
  selector: 'app-top-customers',
  standalone: true,
  imports: [CustomerTabsComponent, CustomerTableComponent, EmptyStateComponent],
  templateUrl: './top-customers.component.html',
  styleUrl: './top-customers.component.scss',
})
export class TopCustomersComponent {
  customers = customers;
  customerOrders = customersOrders;
  customerName = 'Customers Recent transactions';

  updateCustomerOrders(customer: ICustomer) {
    if (customer.id === 'all') {
      this.customerOrders = customersOrders;
      this.customerName = 'Customers Recent transactions';
    } else {
      this.customerOrders = customersOrders.filter((order) => order.customerId == +customer.id);
      this.customerName = customer.name;
    }
  }
}
