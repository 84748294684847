<div class="dashboard flex flex-column justify-content-between p-2 gap-3">
  <div class="dashboard-header flex justify-content-between align-items-center">
    <div class="flex gap-2 align-items-center">
      <img [src]="assetUrl('icons/dashboard.svg')" alt="dashboard" />
      <span class="text-lg font-bold">Dashboard</span>
    </div>
    <div class="flex gap-2 align-items-center link">
      <fa-icon icon="link" class="avatar text-xs"></fa-icon>
      <span class="text-sm">Refferal link</span>
    </div>
  </div>
  <div class="dashboard-grid flex-1">
    <div class="total-revenue">
      <app-total-revenue></app-total-revenue>
    </div>
    <div class="top-customers">
      <app-top-customers></app-top-customers>
    </div>
    <div class="revenue-overview">
      <app-revenue-overview></app-revenue-overview>
    </div>
    <div class="total-customers">
      <app-total-customers></app-total-customers>
    </div>
    <div class="timeline">
      <app-timeline></app-timeline>
    </div>
    <div class="upcoming-products">
      <app-upcoming-products></app-upcoming-products>
    </div>
  </div>
</div>
