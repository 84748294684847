import { ICustomer, ICustomerOrder } from '@app/model';

export const customers: ICustomer[] = [
  { id: 'all', name: 'All', image: 'assets/icons/all-icon.png' },
  { id: 2, name: 'Brian', image: 'assets/person.png' },
  { id: 3, name: 'Nina', image: 'assets/person.png' },
  { id: 4, name: 'Ollie', image: 'assets/person.png' },
];

export const customersOrders: ICustomerOrder[] = [
  {
    orderId: '23231112',
    customerId: 2,
    date: 'Sep 10, 2024',
    amount: 1600,
    status: 'Confirmed',
    earnings: '$70',
  },
  {
    orderId: '23422111',
    customerId: 2,
    date: 'Sep 15, 2024',
    amount: 1200,
    status: 'Pending',
    earnings: '',
  },
  {
    orderId: '23422112',
    customerId: 3,
    date: 'Oct 03, 2024',
    amount: 1200,
    status: 'Pending',
    earnings: '',
  },
  {
    orderId: '23422113',
    customerId: 3,
    date: 'Oct 05, 2024',
    amount: 1100,
    status: 'Confirmed',
    earnings: '$45',
  },
  {
    orderId: '23422114',
    customerId: 4,
    date: 'Oct 10, 2024',
    amount: 1000,
    status: 'Confirmed',
    earnings: '$42',
  },
  {
    orderId: '23422115',
    customerId: 4,
    date: 'Oct 17, 2024',
    amount: 950,
    status: 'Confirmed',
    earnings: '$38',
  },
  {
    orderId: '23422116',
    customerId: 2,
    date: 'Oct 20, 2024',
    amount: 850,
    status: 'Pending',
    earnings: '',
  },
  {
    orderId: '23422117',
    customerId: 3,
    date: 'Oct 25, 2024',
    amount: 750,
    status: 'Confirmed',
    earnings: '$30',
  },
  {
    orderId: '23422118',
    customerId: 4,
    date: 'Oct 30, 2024',
    amount: 650,
    status: 'Pending',
    earnings: '',
  },
];
