import { ChangeDetectionStrategy, Component } from '@angular/core';
import { assetUrl } from '@src/single-spa/asset-url';

@Component({
  selector: 'app-unlock-potenial',
  standalone: true,
  imports: [],
  templateUrl: './unlock-potenial.component.html',
  styleUrl: './unlock-potenial.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnlockPotenialComponent {
  protected readonly assetUrl = assetUrl;
}
