import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { assetUrl } from '@src/single-spa/asset-url';

@NgModule({
  imports: [CommonModule, AngularSvgIconModule.forRoot()],
  exports: [CommonModule, AngularSvgIconModule],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class svgIconModule {
  constructor(private iconReg: SvgIconRegistryService) {
    // if the icon is added here we can use it just by use its name like below
    //      <svg-icon name="calendar"></svg-icon>;
    // if not
    //    <svg-icon src="calendar.svg"></svg-icon>;

    this.iconReg.loadSvg(assetUrl('icons/arrow_forward.svg'), 'arrow-right');
    this.iconReg.loadSvg(assetUrl('icons/path.svg'), 'path');
    this.iconReg.loadSvg(assetUrl('icons/fav.svg'), 'fav');
    this.iconReg.loadSvg(assetUrl('icons/help.svg'), 'help');
    this.iconReg.loadSvg(assetUrl('icons/join.svg'), 'join');
    this.iconReg.loadSvg(assetUrl('icons/orders.svg'), 'orders');
    this.iconReg.loadSvg(assetUrl('icons/collapse.svg'), 'collapse');
    this.iconReg.loadSvg(assetUrl('icons/calendar.svg'), 'calendar');
    this.iconReg.loadSvg(assetUrl('icons/contacts.svg'), 'contacts');
    this.iconReg.loadSvg(assetUrl('icons/customers.svg'), 'customers');
    this.iconReg.loadSvg(assetUrl('icons/dashboard.svg'), 'dashboard');
    this.iconReg.loadSvg(assetUrl('icons/marketplace.svg'), 'marketplace');
    this.iconReg.loadSvg(assetUrl('icons/subscriptions.svg'), 'subscriptions');
    this.iconReg.loadSvg(assetUrl('icons/revenue.svg'), 'revenue');
    this.iconReg.loadSvg(assetUrl('icons/emptyRevenue.svg'), 'emptyRevenue');
    this.iconReg.loadSvg(assetUrl('icons/box.svg'), 'box');
    this.iconReg.loadSvg(assetUrl('icons/timeline.svg'), 'timeline');
    this.iconReg.loadSvg(assetUrl('icons/transactionView.svg'), 'transactionViewIcon');
    this.iconReg.loadSvg(assetUrl('icons/profile-icon.svg'), 'myProfileIcon');
    this.iconReg.loadSvg(assetUrl('icons/onfusionLogo.svg'), 'onfusionLogo');
    this.iconReg.loadSvg(assetUrl('icons/refresh.svg'), 'refresh');
    this.iconReg.loadSvg(assetUrl('icons/shareAffiliate.svg'), 'shareAffiliate');
    this.iconReg.loadSvg(assetUrl('icons/copy.svg'), 'copy');
    this.iconReg.loadSvg(assetUrl('icons/right-arrow.svg'), 'right-arrow');
    this.iconReg.loadSvg(assetUrl('icons/left-arrow.svg'), 'left-arrow');
    this.iconReg.loadSvg(assetUrl('icons/calendar-select.svg'), 'calendar-select');
    this.iconReg.loadSvg(assetUrl('icons/invitation-template.svg'), 'invitation-template');
    this.iconReg.loadSvg(assetUrl('icons/pending.svg'), 'pending');
    this.iconReg.loadSvg(assetUrl('icons/expired.svg'), 'expired');
    this.iconReg.loadSvg(assetUrl('icons/my-contacts.svg'), 'my-contacts');
    this.iconReg.loadSvg(assetUrl('icons/search.svg'), 'search');
    this.iconReg.loadSvg(assetUrl('icons/filter.svg'), 'filter');
    this.iconReg.loadSvg(assetUrl('icons/plus.svg'), 'plus');
    this.iconReg.loadSvg(assetUrl('icons/empty-contacts.svg'), 'empty-contacts');
    this.iconReg.loadSvg(assetUrl('icons/faq.svg'), 'faq');
    this.iconReg.loadSvg(assetUrl('icons/user-manual.svg'), 'user-manual');
    this.iconReg.loadSvg(assetUrl('icons/document.svg'), 'document');
    this.iconReg.loadSvg(assetUrl('icons/my-customers.svg'), 'my-customers');
    this.iconReg.loadSvg(assetUrl('icons/grid-view.svg'), 'grid-view');
    this.iconReg.loadSvg(assetUrl('icons/joined-calendar.svg'), 'joined-calendar');

    // // Register other SVG icons here
  }
}
