import {
  TopCustomersComponent,
  TotalRevenueComponent,
  RevenueOverviewComponent,
  TotalCustomersComponent,
  TimelineComponent,
  UpcomingProductsComponent,
} from '@app/components/dashboard';
import { Component } from '@angular/core';
import { IconsModule } from '@app/shared/modules';
import { assetUrl } from '@src/single-spa/asset-url';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    TopCustomersComponent,
    TotalRevenueComponent,
    RevenueOverviewComponent,
    TotalCustomersComponent,
    TimelineComponent,
    UpcomingProductsComponent,
    IconsModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  protected readonly assetUrl = assetUrl;
}
