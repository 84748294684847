import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IApiResponseWithData } from '@app/model/api.model';
import {
  CustomerSubscriptionParameterOptions,
  ICustomerSubscription,
  IOrder,
  ISubscription,
  OrderParameterOptions
} from '@app/model/subscriptions.model';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MySubscriptionsApi {
  osuite_ApiUrl = environment.apiUrl;

  private httpClient = inject(HttpClient);

  fetchSubscriptions(): Observable<IApiResponseWithData<ISubscription[]>> {
    return this.httpClient.get<IApiResponseWithData<ISubscription[]>>(
      this.osuite_ApiUrl + `my-subscriptions/purchase-history?start=0&limit=100`
    );
  }

  fetchMyOrderHistory(parametersOptions: OrderParameterOptions): Observable<IApiResponseWithData<IOrder[]>> {
    let params = new HttpParams()
      .set('sortBy', parametersOptions.sortBy)
      .set('orderBy', parametersOptions.orderBy)
      .set('startDate', parametersOptions.startDate)
      .set('endDate', parametersOptions.endDate)
      .set('limit', parametersOptions.limit.toString())
      .set('start', parametersOptions.start.toString())
      .set('searchValue', parametersOptions.searchValue);

    return this.httpClient.get<IApiResponseWithData<IOrder[]>>(`${this.osuite_ApiUrl}orders/history`, { params });
  }

  fetchCustomerSubscriptions(
    parametersOptions: CustomerSubscriptionParameterOptions
  ): Observable<IApiResponseWithData<ICustomerSubscription[]>> {
    let params = new HttpParams()
      .set('startDate', parametersOptions.startDate)
      .set('endDate', parametersOptions.endDate)
      .set('size', parametersOptions.size.toString())
      .set('page', parametersOptions.page.toString())
      .set('searchValue', parametersOptions.searchValue);
    return this.httpClient.get<IApiResponseWithData<ICustomerSubscription[]>>(
      `${this.osuite_ApiUrl}myAffiliates/customers/purchase`,
      {
        params
      }
    );
  }

  //api is not ready, revisit when done
  downloadInvoice(orderId: number): Observable<IApiResponseWithData<any>> {
    return this.httpClient.get<IApiResponseWithData<any>>(
      this.osuite_ApiUrl + `orders/paymentInvoice?subsProductId=${orderId}`
    );
  }
}
