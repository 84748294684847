import { inject, Injectable } from '@angular/core';
import { OsuiteState } from './osuite.state';
import { OsuiteApi } from './osuiteApi';
import {
  ICustomerInfo,
  IDashboardRevenue,
  ITimeline,
  ITopCustomer,
  IUpcomingProduct
} from '@app/model/dashboard.model';
import { ICustomerOrder } from '@app/model';
import { FilterType } from '@app/shared/enums/ filter.enum';
import { MyContactsApi } from './my-contacts-api';
import { GetContactsOptions } from '@app/model/my-contacts.model';
import { Observable } from 'rxjs';
import { IStatesData } from '@app/shared/modules/contacts.model';
import { IApiResponseWithData } from '@app/model/api.model';
import { MyCustomersApi } from './my-customers-api';
import { ExportCustomersOptions, GetCustomersOptions } from '@app/model/my-customers.model';
import { MySubscriptionsApi } from './my-subscription';
import { CustomerSubscriptionParameterOptions, OrderParameterOptions } from '@app/model/subscriptions.model';
import { IBillingInfo, IProfileInfo } from '@app/model/myProfile.model';

@Injectable({
  providedIn: 'root'
})
export class OsuiteFacade {
  private osuiteApi = inject(OsuiteApi);
  private myContactsApi = inject(MyContactsApi);
  private myCustomersApi = inject(MyCustomersApi);
  private mySubscriptionsApi = inject(MySubscriptionsApi);

  private osuiteState = inject(OsuiteState);

  fetchUpcomingProducts() {
    return this.osuiteApi.fetchUpcomingProducts();
  }

  getUpcomingProducts() {
    return this.osuiteState.getUpcomingProducts();
  }

  setUpcomingProducts(data: IUpcomingProduct[]) {
    this.osuiteState.setUpcomingProducts(data);
  }

  fetchDashboardRevenue(fromYear: number, toYear: number, fromMonth: number, toMonth: number) {
    return this.osuiteApi.fetchDashboardRevenue(fromYear, toYear, fromMonth, toMonth);
  }

  getDashboardRevenue() {
    return this.osuiteState.getDashboardRevenue();
  }

  setDashboardRevenue(data: IDashboardRevenue) {
    this.osuiteState.setDashboardRevenue(data);
  }

  fetchDashboardTotalRevenue() {
    return this.osuiteApi.fetchDashboardTotalRevenue();
  }

  getDashboardTotalRevenue() {
    return this.osuiteState.getDashboardTotalRevenue();
  }

  setDashboardTotalRevenue(data: any) {
    this.osuiteState.setDashboardTotalRevenue(data);
  }

  fetchTimelineData() {
    return this.osuiteApi.fetchTimelineData();
  }

  getTimelineData() {
    return this.osuiteState.getTimelineData();
  }

  setTimelineData(timelineData: ITimeline[]) {
    return this.osuiteState.setTimelineData(timelineData);
  }

  fetchTopCustomers() {
    return this.osuiteApi.fetchTopCustomers();
  }

  getTopCustomers() {
    return this.osuiteState.getTopCustomers();
  }

  setTopCustomers(data: ITopCustomer[]) {
    this.osuiteState.setTopCustomers(data);
  }

  fetchCustomerInfo() {
    return this.osuiteApi.fetchCustomerInfo();
  }

  getCustomerInfo() {
    return this.osuiteState.getCustomerInfo();
  }

  setCustomerInfo(customerInfo: ICustomerInfo) {
    this.osuiteState.setCustomerInfo(customerInfo);
  }

  fetchMyTotalEarnings(
    start: number,
    limit: number,
    searchValue: string,
    creditType: string,
    productId: number,
    orderBy: string
  ) {
    return this.osuiteApi.fetchMyTotalEarnings(start, limit, searchValue, creditType, productId, orderBy);
  }

  setMyTotalEarnings(data: ICustomerOrder[]) {
    this.osuiteState.setMyTotalEarnings(data);
  }

  getMyTotalEarnings() {
    return this.osuiteState.getMyTotalEarnings();
  }

  getMarketplaceProducts(filter: FilterType, filterFavorite: boolean) {
    return this.osuiteApi.fetchMarketplaceProducts(filter, filterFavorite);
  }

  setMarketplaceProducts(body: any) {
    return this.osuiteApi.setMarketplaceProducts(body);
  }

  deleteFavProducts(productId: number) {
    return this.osuiteApi.deleteFavProducts(productId);
  }

  fetchProfileInfo() {
    return this.osuiteApi.fetchProfileInfo();
  }

  setProfileInfo(data: any) {
    this.osuiteState.setProfileInfo(data);
  }

  updateProfileInfoState(profileInfo: Partial<IProfileInfo>) {
    this.osuiteState.updateProfileInfo(profileInfo);
  }

  updateProfilePic(profilePic: string) {
    this.osuiteState.updateProfilePic(profilePic);
  }

  getProfileInfo() {
    return this.osuiteState.getProfileInfo();
  }

  updateProfileInfo(updatedInfo: any) {
    return this.osuiteApi.updateProfileInfo(updatedInfo);
  }

  uploadProfilePic(file: FormData) {
    return this.osuiteApi.uploadProfilePic(file);
  }

  clearProfilePicture() {
    return this.osuiteApi.clearProfilePicture();
  }

  fetchBillingAddress() {
    return this.osuiteApi.fetchBillingAddress();
  }

  setBillingAddress(data: any) {
    this.osuiteState.setBillingAddress(data);
  }

  updateBillingAddressState(billingAddress: Partial<IBillingInfo>) {
    this.osuiteState.updateBillingAddressState(billingAddress);
  }

  getBillingAddress() {
    return this.osuiteState.getBillingAddress();
  }

  updateBillingAddress(data: any) {
    return this.osuiteApi.updateBillingAddress(data);
  }

  fetchCountries() {
    return this.osuiteApi.fetchCountries();
  }

  setCountries(data: any) {
    this.osuiteState.setCountries(data);
  }

  getCountries() {
    return this.osuiteState.getCountries();
  }

  fetchStatesByCountryId(countryId: number) {
    return this.osuiteApi.fetchStatesByCountryId(countryId);
  }

  fetchCitiesByStateId(stateId: number) {
    return this.osuiteApi.fetchCitiesByStateId(stateId);
  }

  resetPassword(changePasswordData: any) {
    return this.osuiteApi.resetPassword(changePasswordData);
  }

  fetchFirstLogin() {
    return this.osuiteApi.fetchFirstLogin();
  }

  saveNDA(NDAData: any) {
    return this.osuiteApi.saveNDA(NDAData);
  }

  fetchInvitationTemplates(productName: string) {
    return this.osuiteApi.fetchInvitationTemplates(productName);
  }

  fetchInvites(data: any) {
    return this.osuiteApi.fetchInvites(data);
  }

  sendInvites(inviteData: { promotionId: number; emailId: string }) {
    return this.osuiteApi.sendInvites(inviteData);
  }

  // My Contacts

  fetchContacts(options: GetContactsOptions) {
    return this.myContactsApi.fetchContacts(options);
  }
  favoriteContact(contactId: number[]) {
    return this.myContactsApi.favoriteContact(contactId);
  }
  exportContacts(exportData: any) {
    return this.myContactsApi.exportContacts(exportData);
  }

  getCountriesList(): Observable<any | null> {
    return this.osuiteApi.getCountries();
  }

  getStatesByCountryId(countryId: number): Observable<IApiResponseWithData<IStatesData[]>> {
    return this.osuiteApi.getStatesByCountryId(countryId);
  }

  formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  }

  editContact(formData: any): Observable<IApiResponseWithData<IStatesData[]>> {
    return this.osuiteApi.editContact(formData);
  }

  getContactInfoByAlternativeEmail(email: string) {
    return this.osuiteApi.getContactInfoByAlternativeEmail(email);
  }
  deleteContact(idsToDelet: number[]) {
    return this.myContactsApi.deleteContact(idsToDelet);
  }

  createKYCSession() {
    return this.osuiteApi.createKYCSession();
  }

  // My Customers

  fetchCustomers(parameters: GetCustomersOptions) {
    return this.myCustomersApi.fetchCustomers(parameters);
  }

  exportCustomers(parameters: ExportCustomersOptions) {
    return this.myCustomersApi.exportCustomers(parameters);
  }

  AddCustomerFavourite(favCustomerAccountId: number) {
    return this.myCustomersApi.AddCustomerFavourite(favCustomerAccountId);
  }

  removeCustomerFavourite(favCustomerAccountId: number) {
    return this.myCustomersApi.removeCustomerFavourite(favCustomerAccountId);
  }

  // My Subscription

  fetchSubscriptions() {
    return this.mySubscriptionsApi.fetchSubscriptions();
  }

  fetchMyOrderHistory(parametersOptions: OrderParameterOptions) {
    return this.mySubscriptionsApi.fetchMyOrderHistory(parametersOptions);
  }

  fetchCustomerSubscriptions(parametersOptions: CustomerSubscriptionParameterOptions) {
    return this.mySubscriptionsApi.fetchCustomerSubscriptions(parametersOptions);
  }

  downloadInvoice(orderId: number) {
    return this.mySubscriptionsApi.downloadInvoice(orderId);
  }

  // Marketplace

  getPlanInfoById(id: number) {
    return this.osuiteApi.getPlanInfoById(id);
  }
  productOneTimePayment(productPlan: number) {
    return this.osuiteApi.productOneTimePayment(productPlan);
  }

  getProductFeature(productId: number) {
    return this.osuiteApi.getProductFeature(productId);
  }

  setRefreshToken() {
    return this.osuiteApi.setRefreshToken();
  }

  setHasLifetimeBasicPlanSubscription(value: boolean){
    this.osuiteState.setHasLifetimeBasicPlanSubscription(value);
  }

  getHasLifetimeBasicPlanSubscription(){
    return this.osuiteState.getHasLifetimeBasicPlanSubscription();
  }
}
